const handleErrors = async (response) => {
  if (!response.ok) {
    const json = await response.json();
    if (window?.atatus) {
      window.atatus?.notify(new Error(`${json.message}`));
    }

    throw new Error(`${json.message}`);
  }
  return response;
};

export const fetchBuildings = async ({ query = '' } = {}) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/subscriptions/buildings?${query}`,
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message };
  }
};

export const fetchBuildingProducts = async ({ id } = {}) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/subscriptions/buildings/${id}/products`,
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message };
  }
};

export const fetchPassCode = async ({
  reCaptchaToken,
  countryCode,
  phoneNumber,
}) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/uspace/request_pass_code`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          google_recaptcha_token: reCaptchaToken,
          country_code: countryCode,
          phone_number: phoneNumber,
          verify_type: 'invisible',
        }),
      },
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message };
  }
};

export const fetchAuthToken = async ({
  countryCode,
  phoneNumber,
  passCode,
  registrationSource = 'upass',
}) => {
  console.log('fetchAuthToken', countryCode, phoneNumber, passCode);
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/v2/login`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          country_code: countryCode,
          phone_number: phoneNumber,
          pass_code: passCode,
          registration_source: registrationSource,
        }),
      },
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    console.log('error', error);
    return { error: error.message };
  }
};

export const fetchMe = async ({ accessToken }) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/v1/members/me`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message };
  }
};

export const fetchFilterLocations = async ({ query }) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/v1/map/place_autocomplete?${query}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.NEXT_PUBLIC_USPACE_API_KEY}`,
        },
      },
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message };
  }
};

export const fetchGeoLocation = async ({ query }) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/v1/map/place_detail?${query}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.NEXT_PUBLIC_USPACE_API_KEY}`,
        },
      },
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message };
  }
};

export const fetchSpaceList = async ({ query }) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/subscriptions/map/search?${query}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.NEXT_PUBLIC_USPACE_API_KEY}`,
        },
      },
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message };
  }
};

/**
 *
 * @param { accessToken, data }
 * accessToken: string
 * data: {
 *  fullname: string,
 *  email: string,
 * }
 * @returns
 */
export const updateMe = async ({ accessToken, data }) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/v1/members/me`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      },
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message };
  }
};

/**
 * 藍新；傳送首次約定授權參數
 * @param {accessToken, data}
 *  accessToken: string
 *  data: {
 *    returnUrl: string,
 *    orderType: string,
 *  }
 * @returns {error, data}
 * error: string
 * data: {
 *  MerchantID: string,
 *  TradeInfo: string,
 *  TradeSha: string,
 *  Version: string,
 * }
 */
export const fetchCard3DSAuthorizationParams = async ({
  accessToken,
  data,
}) => {
  try {
    const { returnUrl, orderType = 'subscription' } = data;
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/v1/cards/3ds`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          return_url: returnUrl,
          order_type: orderType,
        }),
      },
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message };
  }
};

export const fetchLinePayAuthorizationUrl = async ({ accessToken, data }) => {
  try {
    const { callbackUrl, orderType = 'subscription' } = data;
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/v1/linepay/get_bind_url?order_type=${orderType}&callback_url=${callbackUrl}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message };
  }
};

export const fetchLinePayRegKey = async ({ accessToken, data }) => {
  try {
    const { orderId, orderType, transactionId } = data;
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/v1/linepay/get_regkey?order_id=${orderId}&order_type=${orderType}&transaction_id=${transactionId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message };
  }
};

export const revokeLinePayRegKey = async ({ accessToken }) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/v1/linepay`,
      {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message };
  }
};

// 參考 php-server App\Http\Controllers\MemberController::setDefaultPaymentMethod
// 月租僅支援 card, linepay
const PaymentMethods = ['card', 'linepay', 'jkopay', 'applepay'];
export const updateUserDefaultPaymentMethod = async ({ accessToken, data }) => {
  const { method } = data;
  if (!PaymentMethods.includes(method)) {
    return { error: 'Invalid payment method' };
  }
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/v1/members/me/payment_method/${method}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message };
  }
};

/**
 *
 * @param {accessToken, query}
 *  accessToken: string
 *  query: string (ex: page=1)
 * @returns
 */
export const fetchOrderHistory = async ({ accessToken, query }) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/v1/subscriptions/history?${query}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    ).then(handleErrors);

    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message };
  }
};

/**
 * 新版月租方案 + 訂單查詢。
 * @param {accessToken}
 *  accessToken: string
 * @returns
 */
export const fetchSubscriptionOrders = async ({ accessToken }) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/v1/subscriptions/orders`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message };
  }
};

/**
 * 月租產品計費由後端計算，前端僅提供計算結果
 * @param {accessToken, data}
 * accessToken: string
 * data: {
 * slug: string,
 * activeAt: string, e.g. 2023-09-09
 * }
 * @returns
 */
export const fetchProductWithProRate = async ({ accessToken, data }) => {
  try {
    const { slug, activeAt } = data;
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/subscriptions/products/pro_rate_summary?active_at=${activeAt}&slug=${slug}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message };
  }
};

/**
 *
 * @param {accessToken, data}
 * accessToken: string
 * data: {
 * buildingId: string,
 * productId: string,
 * paymentMethod: string,
 * activeAt: string, e.g. 2023-09-09
 * }
 * @returns
 */
export const submitOrder = async ({ accessToken, data }) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/subscriptions/orders`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          subscription_product_id: data.productId,
          active_at: data.activeAt,
          car_numbers: data.carNumbers,
          utm: data.utm,
          payment_method: data.payment_method
        }),
      },
    ).then(handleErrors);
    const json = await response.json();
    console.log('submitOrder', json);
    return json;
  } catch (error) {
    console.log('submitOrder error', error);
    return { error: error.message, data };
  }
};

/**
 * 刪除信用卡綁定
 * @param {accessToken, data}
 *  accessToken: string
 *  data: {
 *    cardId: string,
 * }
 * @returns
 */
export const removeCreditCard = async ({ accessToken, data }) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/v1/cards/${data.cardId}`,
      {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    ).then(handleErrors);

    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message };
  }
};

/**
 * 解除 Line Pay 綁定
 * @param {accessToken}
 * @returns
 */
export const removeLinePay = async ({ accessToken }) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/v1/linepay`,
      {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    ).then(handleErrors);

    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message };
  }
};

export const joinWaitList = async ({ accessToken, data }) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/subscriptions/waiting_list/join`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      },
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message, data };
  }
};

export const fetchWaitList = async ({ accessToken }) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/subscriptions/waiting_list/me`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    console.log('error', error);
    return { error: error.message };
  }
};

export const applySubscription = async ({ accessToken, data }) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/subscriptions/apply`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      },
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message, data };
  }
};

export const repayOrder = async ({ accessToken, data }) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/subscriptions/orders/${data.orderId}/repay`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message, data };
  }
};

export const updateSubscriptionActive = async ({ accessToken, data }) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/subscriptions/${data.id}/update`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          active: data.isActive,
        }),
      },
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message, data };
  }
};

export const updateSubscriptionCarNumber = async ({ accessToken, data }) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/subscriptions/${data.id}/update_car_numbers`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          car_numbers: data.carNumbers,
        }),
      },
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    console.log('error', error);
    return { error: error.message, data };
  }
};

export const fetchAccessTokenByOneTimeToken = async ({ data }) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/token_exchange/exchange`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          onetime_token: data.token,
        }),
      },
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message, data };
  }
};

export const fetchBuildingsByZones = async ({ data } = {}) => {
  try {
    const { group, city, zone } = data;
    const params = new URLSearchParams();
    if (group) {
      params.append('group', group);
    }
    if (city) {
      params.append('city', city);
    }
    if (zone) {
      params.append('zone', zone);
    }
    const response = await fetch(
      `${
        process.env.NEXT_PUBLIC_API_URL
      }/subscriptions/zones?${params.toString()}`,
    ).then(handleErrors);
    const json = await response.json();
    return json;
  } catch (error) {
    return { error: error.message, data };
  }
};

const service = {
  fetchBuildings,
  fetchBuildingProducts,
  fetchPassCode,
  fetchAuthToken,
  fetchMe,
  updateMe,
  fetchFilterLocations,
  fetchGeoLocation,
  fetchSpaceList,
  fetchCard3DSAuthorizationParams,
  fetchLinePayAuthorizationUrl,
  fetchOrderHistory,
  fetchSubscriptionOrders,
  fetchLinePayRegKey,
  revokeLinePayRegKey,
  updateUserDefaultPaymentMethod,
  fetchProductWithProRate,
  submitOrder,
  joinWaitList,
  fetchWaitList,
  applySubscription,
  repayOrder,
  updateSubscriptionActive,
  removeCreditCard,
  removeLinePay,
  updateSubscriptionCarNumber,
  fetchAccessTokenByOneTimeToken,
  fetchBuildingsByZones,
};
export default service;
