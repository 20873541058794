// export const DEFAULT_MAP_CENTER = {
//   lat: 25.05807,
//   lng: 121.53748,
// };

export const DEFAULT_MAP_CENTER = {
  lat: 25.080426196242,
  lng: 121.56746957141,
};
export const DEFAULT_MAP_ZOOM = 14;

export const NAVBAR_LINKS = [
  // { text: '尋找車位', href: '/' },
  { text: '地圖', href: '/' },
  { text: '全部車位', href: '/zones' },
  { text: '申請指南 Q&A', href: '/apply' },
  // { text: '提供停車場', href: '/supply' },
];

export const DEFAULT_PRICING_RANGE_MAX = '8000';
export const DEFAULT_PRICING_RANGE_MIN = '3000';

export const NOT_HEADQUARTER_SERVICE_AREA = [
  '台中市',
  '彰化縣',
  '彰化市',
  '南投縣',
];

export const USER_PAYMENT_METHODS = [
  {
    value: 'card',
    text: '信用卡/金融卡',
    defaultButtonText: '前往開通',
  },
  {
    value: 'linepay',
    text: 'LINE Pay',
    defaultButtonText: '前往開通',
  },
  {
    value: 'vacc_kgi_personal',
    text: '虛擬帳號（凱基）',
    defaultButtonText: '',
  },
];

export const TOTAL_USER_PAYMENT_METHODS = [
  {
    value: 'card',
    text: '信用卡/金融卡',
  },
  {
    value: 'linepay',
    text: 'LINE Pay',
  },
  {
    value: 'jkopay',
    text: '街口支付',
  },
  {
    value: 'applepay',
    text: 'Apple Pay',
  },
  {
    value: 'tappay_creditcard',
    text: '信用卡/AE 卡',
  },
  {
    value: 'vacc_kgi_personal',
    text: '虛擬帳號（凱基）',
  },
  {
    value: 'vacc_kgi',
    text: '虛擬帳號（凱基）',
  },
  {
    value: 'vacc',
    text: '虛擬帳戶',
  },
  {
    value: 'atm',
    text: 'ATM 轉帳',
  }
];

export const PERIOD_TYPES = {
  full_day: '全日',
  day: '日間',
  night: '夜間',
};

export const TERMS = `
月租車位訂閱服務契約書

1. 關於USPACE月租訂閱服務

  1.1 歡迎使用USPACE（下稱甲方）月租車位訂閱服務（下稱「本服務」），申請本服務的用戶（下稱「乙方」），請詳細閱讀本契約條款（下稱「本條款」)，若您完成本服務付款時，即表示您已於合理期間內充分審閱、了解並同意以下所有約定，並同意除本條款特別約定之事項外，其他於USPACE官方網站所公告之政策（包括但不限於使用者條款、隱私權政策、本服務頁面公告等不時更新者）仍繼續適用，若您不同意以下任何條款內容，請勿使用本服務。

  1.2 本服務可能有名額及/或申請資格等限制，USPACE對本服務之申請保有最終核准與否之權利並得自動取消您的訂閱申請。

2. 訂閱週期

  2.1 本服務計費周期，除首期費用以首次付款成功至該月底以「日」計算外，最短計費週期以「月」為單位，且當起租日晚於該月15日時，乙方應連同次月份續訂費用一次繳付之。

  2.2 首次付款成功早於每月15日，計費周期舉例如下：
  
  如於2023/03/10付費訂閱成功，您的首期訂閱期間為2023/03/11-2023/03/31，並以此計算首期費用；若您使用信用卡且於03/26付款成功，則您的訂閱將在當年度的04/01（續訂生效日）自動續約，第二期訂閱期間則為2023/04/01-2023/04/30，以此類推。

  2.3 首次付款成功晚於每月15日時，需連同次月份續訂費用一起繳交，計費週期舉例如下：
  
  如於2024/03/20付費訂閱成功，您的首期訂閱期間為2024/03/21-2024/04/30；若您使用信用卡且於4/26付款成功，則您的訂閱將在當年度的05/01（續訂生效日）自動續約，第二期訂閱期間為2024/05/01-2024/05/30，以此類推。
  
3. 付款

  3.1 乙方付款方式可以選擇頁面上適合之支付方式，且當乙方選擇非虛擬帳戶作為本服務之訂閱支付時，即視為您同意授權USPACE在訂閱及後續每次計費週期續約時，若該支付支援預授權功能，將使用該支付方式自動扣款功能為您自動續訂本服務，除非乙方按本條款第5條自行取消訂閱，否則系統將依據您所選擇之支付方式定期收取費用。

  3.2 每月26日為續訂付款日，甲方將於續訂付款日從乙方所選擇之支付方式扣除續訂費用，如遇信用卡扣款失敗時，乙方可於訂單頁面自行操作補刷，或授權甲方於同月27日再次自動扣款。如未能於續訂付款日當月最後一日確認付款成功之時，甲方將逕行解除月租訂閱，乙方於隔月1日起將不再享有相關權利。

  3.3 訂閱期間與退費：乙方可於每月26日續訂付款日前取消或 恢復本服務，相關權利於隔月1日起生效。若於該月26日扣款成功後始進行取消訂閱者，退費規定依「5.2特殊退款辦法」規定辦理，另甲方於隔月26日終止自動扣款。下列場域月租退費規定適用甲方「5.2特殊退款辦法」：USPACE正氣橋下平面停車場、長春路龍江路平面停車場、吉祥平面停車場、昆陽捷運站旁平面停車場，甲方保有增減最終適用「5.2特殊退款辦法」場域之權利。

  3.4 若乙方不同意授權透過本條所限制之支付方式續訂本服務，請依據第5條規定取消訂閱。

4. 變更

  4.1 本服務（以及任何適用的限制）請以本服務網頁公告為準，若有內容調整，甲方將以合理方式通知，包括但不限於透過App推播、電子郵件、簡訊或本服務頁面公告等。本條規範下的訂閱內容調整，將於乙方次一計費周期方案續訂時生效，不影響續訂前的使用，若您不同意該等調整，請按第5條規定取消續訂本服務，未取消者視為同意。

5. 取消

  5.1 除首期費用不得退款外，乙方可以隨時取消續訂本服務，然而該等取消將於次一計費周期的續訂日期生效。若您不欲續訂，您可於續訂付款日（每月26日）前於本服務網站取消訂閱本服務，以避免額外費用的產生。

  5.2 特殊退款辦法： 於甲方公告特定場域適用，於訂閱期間內辦理退費，其流程如下： 
    5.2.1 乙方向甲方客服提出欲於訂閱期間退款之意思表示，由甲方提供乙方退款同意書
    5.2.2 乙方填寫退款同意書及提供個人相關資訊
    5.2.3 甲方依乙方實際月內(如有)使用天數按比例進行退款

  5.3 本服務在乙方未提出續約終止前，將自動展延，且每次展延週期以乙方所選擇的訂閱週期為單位。

6. 發票

  6.1 響應政府發票電子化政策，甲方應於扣款成功後開立電子發票並郵寄至乙方訂閱時所填寫的Email信箱，檔案列印出後即具效力，將不另外寄送紙本發票。

  6.2 乙方同意如發票超過作廢期限時，授權甲方代為處理網路銷貨折讓單以加速相關流程。

7. 損害賠償

  7.1 乙方應遵守甲方所訂相關停車場規定使用，並不得將租賃標的物供非法使用或存放危險物品影響公共安全，若造 成甲方之損害，乙方應負一切賠償責任。

  7.2 本停車場僅提供停車，不負保管之責，乙方不可將貴重物品留於車內，如因其他不可抗力或天然災害等致車輛受 損，甲方概不負賠償之責任，乙方亦不得要求任何賠償。

  7.3 乙方使用甲方停車位，如因人為操作疏失，造成甲方或他人財產或停車場設備損失，經佐證確認責任歸屬後，乙方應負一切賠償責任。

8. 終止

  8.1 乙方不得將車位作為設定擔保或為其他之負擔，違約者將終止契約，已繳訂閱費用將不予退還。

  8.2 訂閱期間屆滿或經甲方於續訂扣款日前表示不再續約之時，乙方應於續訂生效日前將租賃標的物回復原狀返還甲方且不得拖延，否則應自續訂生效日起，每日按租金貳倍計算賠償金予甲方。甲方並得委派強制拖吊該車輛，拖吊費用由 乙方負擔，甲方並保留任何停車位及設備毀損之損害賠償請求權，乙方不得異議。

  8.3 乙方有下列情形之一，且經甲方催告後而仍未改正時，甲方得終止本契約：

    8.3.1 以租賃物供違反法令之使用時或違反租賃契約任一規定時。

    8.3.2 損壞租賃物或附著物，而不為賠償之時。
  
  8.4 甲方如因業務需要得於一個月前通知乙方終止本契約，乙方不得有異議，且不得向甲方請求任何賠償或補償。

9. 一般條款
  9.1 乙方不得擅將本契約之權利、義務移轉予其他任意第三人。

  9.2 如甲方與甲方關係企業（按台灣公司法定義）辦理分割或合併時，乙方同意，甲方於本契約之一切權利義務於分割基準日或合併基準日起，轉讓予甲方分割後受讓營業之既存或新設公司，或合併後之存續公司或新設公司。

  9.3 您同意以電子文件為本服務申請及相關契約之表示方式。

10. 手工洗車訂閱制特別條款
  10.1 乙方如當月未使用完畢手工洗車次數，不得延至下月使用，且不得要求退費。

`;

export const INVOICE_OPTIONS = [
  {
    text: '捐贈發票',
    value: 'donate',
    info_1_label: '系統代碼',
    info_2_label: '愛心碼',
    options: [
      {
        text: '社團法人台北市基督教教會聯合會',
        info_1: 'a01',
        info_2: '7676',
      },
      {
        text: '財團法人義美環境保護基金會',
        info_1: 'b01',
        info_2: '55211',
      },
      {
        text: '社團法人台灣防止虐待動物協會',
        info_1: 'a03',
        info_2: '1772',
      },
      {
        text: '財團法人桃園市真善美社會福利基金會',
        info_1: 'a04',
        info_2: '268',
      },
      {
        text: '其他受贈機關團體-請輸入捐贈碼',
        info_1: '',
        info_2: '',
      },
    ],
  },
  {
    text: '一般發票',
    value: 'normal',
    info_1_label: '空字串',
    info_2_label: '姓名',
  },
  {
    text: '手機載具',
    value: 'vehicle',
    info_1_label: '手機條碼',
    info_2_label: '手機條碼',
  },
  {
    text: '統編發票',
    value: 'company',
    info_1_label: '統一編號',
    info_2_label: '發票抬頭',
  },
];

export const PAYMENT_STATUS = {
  paid: '已付款',
  unpaid: '未付款',
  payment_failed: '付款失敗',
  refund: '已退款',
  revoke: '撤銷',
};
